import React, {createContext, useContext, useEffect, useState} from 'react';
import {HashRouter as Router, Route, Routes, Navigate, useNavigate} from "react-router-dom";
import Accueil from "./Accueil";
import axios from "axios";
import Login from "./Login";
import WithNavigate from "./Login";
import {AuthContext} from "./auth-context";
import CreationCompte from "./CreationCompte";
import Container from "@mui/material/Container";
import Resultats from "./Resultats";
import Admin from "./Admin";
import Grille from "./Grille";

export default function RouterComponent() {
    let affichage;
    affichage = (
        <ProvideAuth>

            <Router>
                <Routes>
                    <Route path='/accueil' element={<PrivateRoute><Accueil/></PrivateRoute>}>

                    </Route>

                    <Route path='/grille' element={<PrivateRoute><Grille/></PrivateRoute>}>

                    </Route>

                    <Route path='/resultats' element={<PrivateRoute><Resultats/></PrivateRoute>}>

                    </Route>

                    <Route path='/admin' element={<AdminRoute><Admin/></AdminRoute>}>

                    </Route>

                    <Route path='/' element={<WithNavigate/>}>

                    </Route>

                    <Route path='/creationCompte' element={<CreationCompte/>}>

                    </Route>

                </Routes>
            </Router>
        </ProvideAuth>
    );


    return affichage;

}

function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    );
}


function PrivateRoute({children}) {
    let context = useContext(AuthContext);
    const [affichage, setAffichage] = useState(null);
    useEffect(() => {
        if (context.user) {
            setAffichage(children);
        } else {
            axios.get('/api/user/check').then((res) => {
                context.signin(res.data);
                setAffichage(children);
            }).catch((error) => {
                console.error(error);
                setAffichage(<Navigate to='/'/>);
            });
        }

    }, [context.user, children]);
    return affichage;
}

function AdminRoute({children}) {
    let context = useContext(AuthContext);
    const [affichage, setAffichage] = useState(null);
    useEffect(() => {
        if (context.user && context.user.roles.findIndex(c => c.authority == "ROLE_ADMIN") >= 0) {
            setAffichage(children);
        } else {
            axios.get('/api/user/check').then((res) => {
                if (res.data.roles.findIndex(c => c.authority == "ROLE_ADMIN") >= 0) {
                    context.signin(res.data);
                    setAffichage(children);
                } else {
                    setAffichage(<Navigate to='/'/>);
                }
            }).catch((error) => {
                console.error(error);
                setAffichage(<Navigate to='/'/>);
            });
        }

    }, [context.user, children]);
    return affichage;
}


function useProvideAuth() {
    const [user, setUser] = useState(null);
    const signin = (user) => {
        setUser(user);
    };

    const signout = () => {
        setUser(null);
    }
    return {
        signin,
        signout,
        user
    };
}





