import React, {useContext, useEffect} from 'react';
import {Theme} from "./theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import AppbarNav from "./AppbarNav";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import axios, {AxiosError} from "axios";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Container from "@mui/material/Container";
import CardRencontre from "./CardRencontre";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Whatshot} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import CardPari from "./CardPari";
import {Parier} from "../model/Parier";
import {AuthContext} from "./auth-context";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CeriseV2 from '../assets/CerisesV2.png';
import Grenade from '../assets/grenade.png';
import Grappa from '../assets/grappa.png';
import * as webPush from './WebPushNotification'
import { ClickAwayListener, Tooltip } from '@mui/material';

export default function Accueil() {
    const [allJournees, setAllJournees] = React.useState(<MenuItem></MenuItem>);
    const [journeeSelected, setJourneeSelected] = React.useState('');
    const [matchsIHM, setMatchsIHM] = React.useState(<Card></Card>);
    const [pariValider, setPariValider] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [dateValide, setDateValide] = React.useState(new Date().toLocaleString());
    const [open, setOpen] = React.useState(false);
    const [openNotif, setOpenNotif] = React.useState(false);
    const [infoParis, setInfoParis] = React.useState({});
    const [nbCeriseJouerLive, setNbCeriseJouerLive] = React.useState(0);
    const [nbGrappaJouerLive, setNbGrappaJouerLive] = React.useState(0);

    const [nbBombeJouerLive, setNbBombeJouerLive] = React.useState(0);
    const [openToolTip, setOpenToolTip] = React.useState(false);
    const [openToolTipCerise, setOpenToolTipCerise] = React.useState(false);
    const [openToolTipFire, setOpenToolTipFire] = React.useState(false);
    const [openToolTipGrappa, setOpenToolTipGrappa] = React.useState(false);
    const [nbFireLive, setNbFireLive] = React.useState(0);
    const [droitJouerBombeLive, setDroitJouerBombeLive] = React.useState(false);
    const [droitJouerCeriseLive, setDroitJouerCerise] = React.useState(false);
    const [droitJouerBananeLive, setDroitJouerBanane] = React.useState(false);
    const [droitJouerGrappaLive, setDroitJouerGrappaLive] = React.useState(false);
    

    const grenadeBackground = {
        backgroundImage: `url(${Grenade})`,
        width: '38px',
        height: '41px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    };
    const grappaBackground = {
        backgroundImage: `url(${Grappa})`,
        width: '38px',
        height: '41px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    };
    const ceriseBackground = {
        backgroundImage: `url(${CeriseV2})`,
        width: '30px',
        height: '30px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    };

    let context = useContext(AuthContext);

    let nbCeriseMax = 2;
    let nbCeriseJoue = 0;
    let nbBombeMax = 1;
    let nbBombeJoue = 0;
    let nbBananeMax = 1;
    let nbBananeJoue = 0;
    let nbGrappaMax = 1;
    let nbGrappaJoue = 0;
    let droitJouerCerise = false;
    let droitJouerGrappa = false;
    let droitJouerBombe = false;
    let droitJouerBanane = false;
    let parisJournees = [];

    useEffect(() => {
        /*
        axios.get('/api/journees/findAllParis').then((res) => {
            if (res.data.length) {
                setAllJournees(res.data.map((j) =>
                    <MenuItem key={j.numero_journee} value={j.numero_journee}>{j.numero_journee}</MenuItem>
                ));
                setJourneeSelected(Math.max(...res.data.map(j => j.numero_journee)));
            }

        }).catch((error) => {
            console.error(error);
        });

         */
        findAllParis();
/*
        if ('serviceWorker' in navigator) {
            // Register a Service Worker.
            //navigator.serviceWorker.register('/sw.js');
            navigator.serviceWorker.register('/sw.js').then(webPush.initialiseState(context.user, setOpenNotif));
        } else {
            console.warn('Service workers are not supported in this browser.');
        }
        */
    }, []);

    const findAllParis = async () => {
        try {
            let res = await axios.get('/api/journees/findAllParis');
            if (res.data.length) {
                setAllJournees(res.data.map((j) =>
                    <MenuItem key={j.numero_journee} value={j.numero_journee}>{j.numero_journee}</MenuItem>
                ));
                setJourneeSelected(Math.max(...res.data.map(j => j.numero_journee)));
            }
        } catch (error) {
            console.error(error);
        }

    };


    useEffect(() => {
        setLoading(true);
        let matchData;
        if (journeeSelected) {
            let numJouClassement = journeeSelected == 1 ? journeeSelected : (journeeSelected - 1);
            classementGeneral();
            classementJournee();
            findByJournee();

        } else {
            setLoading(false);
        }


    }, [journeeSelected]);

    const classementGeneral = async () => {
        try {
            let classG = await axios.get('/api/classement/general');
            determinerBonus(classG.data, 'autre');
        } catch (error) {
            setLoading(false);
        }
    };

    const handleTooltipClose = () => {
        setOpenToolTip(false);
    };

    const handleTooltipOpen = () => {
        if (openToolTip) {
            setOpenToolTip(false);
        } else {
            setOpenToolTip(true);
        }
        
    };

    const handleTooltipCloseCerise = () => {
        setOpenToolTipCerise(false);
    };

    const handleTooltipOpenCerise = () => {
        if (openToolTipCerise) {
            setOpenToolTipCerise(false);
        } else {
            setOpenToolTipCerise(true);
        }
        
    };
    
    const handleTooltipCloseFire = () => {
        setOpenToolTipFire(false);
    };

    
    const handleTooltipCloseGrappa = () => {
        setOpenToolTipGrappa(false);
    };

    const handleTooltipOpenFire = () => {
        if (openToolTipFire) {
            setOpenToolTipFire(false);
        } else {
            setOpenToolTipFire(true);
        }
        
    };

    const handleTooltipOpenGrappa = () => {
        if (openToolTipGrappa) {
            setOpenToolTipGrappa(false);
        } else {
            setOpenToolTipGrappa(true);
        }
        
    };
    

    const classementJournee = async () => {
        try {
            let numJouClassement = journeeSelected === 1 ? journeeSelected : (journeeSelected - 1);
            let classJ = await axios.get('/api/classement/journee', {params: {journee: numJouClassement}});
            determinerBonus(classJ.data, 'banane');
            determinerBonus(classJ.data, 'grappa');
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const findByJournee = async () => {
        try {
           let journeeByMatch = await axios.get('/api/rencontres/findByJournees', {params: {numero: journeeSelected}});
            setDateValide(new Date(Math.min.apply(null, journeeByMatch.data.map((j) => new Date(j.date)))).toLocaleString());


            let pariJ = await axios.get('/api/parier/joueurJournee', {params: {journee: journeeSelected}});
            parisJournees = pariJ.data;
            setPariValider(pariJ.data);
            determinerBonusDejaJouer(pariJ.data);
            setMatchsIHM(journeeByMatch.data.map((match) =>
                <CardPari
                    match={match}
                    pari={pariJ.data}
                    droitJouerBanane={droitJouerBanane}
                    droitJouerBombe={droitJouerBombe}
                    droitJouerCerise={droitJouerCerise}
                    droitJouerGrappa={droitJouerGrappa}
                    handleChangeBanane={handleChangeBanane}
                    handleChangeBombe={handleChangeBombe}
                    handleChangeParis={handleChangeParis}
                    handleChangeCerise={handleChangeCerise}
                    handleChangeGrappa={handleChangeGrappa}
                    key={match.id_rencontres}
                />
            ));
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const determinerBonusDejaJouer = (parisList) => {
        parisList.forEach(p => {
            if (p.cerise) {
                nbCeriseJoue++;
            }
            if (p.bombe) {
                nbBombeJoue++;
            }
            if (p.onfire) {
                nbBananeJoue++;
            }
            if (p.grappa) {
                nbGrappaJoue++;
            }
        });
        setNbBombeJouerLive(nbBombeMax - nbBombeJoue);
        setNbCeriseJouerLive(nbCeriseMax - nbCeriseJoue);
        setNbFireLive(nbBananeMax - nbBananeJoue);
        setNbGrappaJouerLive(nbGrappaMax - nbGrappaJoue);
    };


    const determinerBonus = (classement, type) => {
        if (journeeSelected === 1) {
            droitJouerBanane = false;
            setDroitJouerBanane(false);
            droitJouerBombe = false;
            setDroitJouerBombeLive(false);
            droitJouerCerise = false;
            setDroitJouerCerise(false);
            droitJouerGrappa = false;
            setDroitJouerGrappaLive(false);
        } else {

            let placeBombes = [2, 3, 4];
            let placeCerise = [classement.length, classement.length - 1, classement.length - 2];
            if (type === 'banane') {
                // let index = classement.findIndex(c => c.clePrimaireClassement.userApp.username == context.user.username);
                //let nbBananeFind = classement.filter(b => b.points === classement[index].points).length;
                const newTab = [];
                classement.forEach((c, index) => {
                    if (index === 0) {
                        newTab.push({data: c, num: 1});
                    } else {
                        if (c.points === classement[index - 1].points) {
                            newTab.push({data: c, num: newTab[index - 1].num});
                        } else {
                            newTab.push({data: c, num: index + 1});
                        }
                    }
                });
                let positionJoueur = newTab.find(fi => fi.data.clePrimaireClassement.userApp.username === context.user.username)?.num;
                if (positionJoueur === 1) {
                    droitJouerBanane = true;
                    setDroitJouerBanane(true);
                } else {
                    droitJouerBanane = false;
                    setDroitJouerBanane(false);
                }
            } else if (type === 'grappa') {
                let classJoueur = classement.find(x => x.clePrimaireClassement.userApp.username === context.user.username);
                let dernierJoueur = classement[classement.length-1];
                if (classJoueur.points === dernierJoueur.points && classJoueur.matchOK === dernierJoueur.matchOK) {
                    droitJouerGrappa = true;
                    setDroitJouerGrappaLive(true);
                } else {
                    droitJouerGrappa = false;
                    setDroitJouerGrappaLive(false);
                }

                console.log('grappa ',droitJouerGrappa);
                console.log('droitJouerGrappaLive ', droitJouerGrappaLive);
                console.log('nbGrappaJouerLive ', nbGrappaJouerLive);
            } else {
                let index = classement.findIndex(c => c.username === context.user.username);
                if (index >= 0) {
                    /* Ancienne méthode
                    let nbPersonneBombes = calculNbPointsEgalite(classement, placeBombes, index);
                    let nbPersonneCerise = calculNbPointsEgalite(classement, placeCerise, index);
                                        if ((placeBombes.findIndex(c => index === c) >= 0) && index !== classement.length - 1 && nbPersonneBombes) {
                        droitJouerBombe = true;
                    } else if (placeCerise.findIndex(c => index === c) >= 0 && nbPersonneCerise ) {
                        droitJouerCerise = true;
                    }
                     */
                    droitJouerBombe = calculNbPointsEgalite(classement, placeBombes);
                    setDroitJouerBombeLive(calculNbPointsEgalite(classement, placeBombes));
                    console.log('setDroitJouerBombeLive ', droitJouerBombeLive);
                    droitJouerCerise = calculNbPointsEgalite(classement, placeCerise);
                    setDroitJouerCerise(calculNbPointsEgalite(classement, placeCerise));
                }
            }


        }

    };

    const calculNbPointsEgalite = (classementG, place) => {
        const newTab = [];
        classementG.forEach((c, index) => {
            if (index === 0) {
                newTab.push({data: c, num: 1});
            } else {
                if (c.total === classementG[index - 1].total && c.pourcentages === classementG[index - 1].pourcentages) {
                    newTab.push({data: c, num: newTab[index - 1].num});
                } else {
                    newTab.push({data: c, num: index + 1});
                }
            }
        });
        let position = newTab.find(nt => nt.data.username === context.user.username)?.num;
        return place.findIndex(f => f === position) > -1;
    };

    const calculNbPointsEgaliteJournee = (classementJ, place) => {
        const newTab = [];
        classementJ.forEach((c, index) => {
            if (index === 0) {
                newTab.push({data: c, num: 1});
            } else {
                if (c.total === classementJ[index - 1].points) {
                    newTab.push({data: c, num: newTab[index - 1].num});
                } else {
                    newTab.push({data: c, num: index + 1});
                }
            }
        });
        let position = newTab.find(nt => nt.data.clePrimaireClassement.userApp.username === context.user.username)?.num;
        return place.findIndex(f => f === position) > -1;
    };

    const handleChangeBanane = (sens, idMatch) => {
        const tabClone = parisJournees.slice();
        const pariIndex = tabClone.findIndex(c => c.idRencontres.toString() == idMatch);
        if (handleChangeBonus(sens, droitJouerBanane, nbBananeJoue, nbBananeMax) || sens === '-') {
            if (pariIndex >= 0) {
                if (sens === '+') {
                    nbBananeJoue++;
                    tabClone[pariIndex]['onfire'] = '1';
                } else {
                    nbBananeJoue--;
                    tabClone[pariIndex]['onfire'] = '';
                }
                parisJournees = tabClone;
                setPariValider(tabClone);
            } else {
                const valFire = sens === '+' ? '1' : '';
                if (sens === '+') {
                    nbBananeJoue++;
                } else {
                    nbBananeJoue--;
                }
                const newPari = new Parier('', '', '', '', idMatch, '', '', valFire, context.user.username, '');
                tabClone.push(newPari);
                parisJournees = tabClone;
                setPariValider(tabClone);
            }
            setNbFireLive(nbBananeMax - nbBananeJoue);
            return true;
        } else {
            return false;
        }
    };

    const handleChangeBombe = (sens, idMatch) => {
        const tabClone = parisJournees.slice();
        const pariIndex = parisJournees.findIndex(c => c.idRencontres.toString() == idMatch);
        if (handleChangeBonus(sens, droitJouerBombe, nbBombeJoue, nbBombeMax)) {
            if (pariIndex >= 0) {
                if (sens === '+') {
                    nbBombeJoue++;
                    tabClone[pariIndex]['bombe'] = '1';
                } else {
                    nbBombeJoue--;
                    tabClone[pariIndex]['bombe'] = '';
                }
                parisJournees = tabClone;
                setPariValider(tabClone);
            } else {
                const valBombe = sens === '+' ? '1' : '';
                if (sens === '+') {
                    nbBombeJoue++;
                } else {
                    nbBombeJoue--;
                }
                const newPari = new Parier('', '', valBombe, '', idMatch, '', '', '', context.user.username, '');
                tabClone.push(newPari);
                parisJournees = tabClone;
                setPariValider(tabClone);
            }
            setNbBombeJouerLive(nbBombeMax - nbBombeJoue);
            return true;
        } else {
            return false;
        }
    };

    const handleChangeGrappa = (sens, idMatch) => {
        const tabClone = parisJournees.slice();
        const pariIndex = parisJournees.findIndex(c => c.idRencontres.toString() === idMatch);
        if (handleChangeBonus(sens, droitJouerGrappa, nbGrappaJoue, nbGrappaMax)) {
            if (pariIndex >= 0) {
                if (sens === '+') {
                    nbGrappaJoue++;
                    tabClone[pariIndex]['grappa'] = '1';
                } else {
                    nbGrappaJoue--;
                    tabClone[pariIndex]['grappa'] = '';
                }
                parisJournees = tabClone;
                setPariValider(tabClone);
            } else {
                const valGrappa = sens === '+' ? '1' : '';
                if (sens === '+') {
                    nbGrappaJoue++;
                } else {
                    nbGrappaJoue--;
                }
                const newPari = new Parier('', '', '', '', idMatch, '', '', '', context.user.username, '', valGrappa);
                tabClone.push(newPari);
                parisJournees = tabClone;
                setPariValider(tabClone);
            }
            setNbGrappaJouerLive(nbGrappaMax - nbGrappaJoue);
            return true;
        } else {
            return false;
        }
    };

    const handleChangeParis = (valPari, idMatch) => {
        const tabClone = parisJournees.slice();
        let pariIndex = tabClone.findIndex(c => c.idRencontres.toString() == idMatch);
        if (pariIndex >= 0) {
            tabClone[pariIndex]['valeurParis'] = valPari;
            parisJournees = tabClone;
            setPariValider(tabClone);
        } else {
            const newPari = new Parier('', '', '', '', idMatch, '', '', '', context.user.username, valPari);
            tabClone.push(newPari);
            parisJournees = tabClone;
            setPariValider(tabClone);
        }
    };

    const handleChangeCerise = (valPari, idMatch, sens) => {
        const tabClone = parisJournees.slice();
        let pariIndex = tabClone.findIndex(c => c.idRencontres.toString() == idMatch);
        if (pariIndex >= 0 && tabClone[pariIndex].cerise && valPari) {
            tabClone[pariIndex]['cerise'] = valPari;
            parisJournees = tabClone;
            setPariValider(tabClone);
            return true;
        }
        if (handleChangeBonus(sens, droitJouerCerise, nbCeriseJoue, nbCeriseMax)) {
            if (valPari) {
                nbCeriseJoue++;
            } else {
                nbCeriseJoue--;
            }
            if (pariIndex >= 0) {
                tabClone[pariIndex]['cerise'] = valPari;
                parisJournees = tabClone;
                setPariValider(tabClone);
            } else {
                const newPari = new Parier('', '', '', valPari, idMatch, '', '', '', context.user.username, '');
                tabClone.push(newPari);
                parisJournees = tabClone;
                setPariValider(tabClone);
            }
            setNbCeriseJouerLive(nbCeriseMax - nbCeriseJoue);
            return true;
        } else {
            return false;
        }
    };

    const handleClickValider = () => {
        if (pariValider.length === matchsIHM.length && verifBonusParier()) {
            axios.post('/api/parier/joueurJourneeMAJ', pariValider).then((res) => {
                if (res.status === 200) {
                    setInfoParis({titre: `Informations`,msg:`Tes paris sont validés à toi l'ananas !`});
                }
                setOpen(true);
            }).catch((error) => {
                console.error(error.response);
                if (error.response.status === 417) {
                    setInfoParis({titre: `!!! Attention !!!`,msg:`T'es en retard attend la prochaine journée !`});
                } else {
                    setInfoParis({titre: `!!! Attention !!!`,msg:`Bip Bip erreur préviens l'admin donne lui le code erreur ==> ${error.response.status} !`});
                }
                setOpen(true);
            });
        } else {
            setInfoParis({titre: `!!! Attention !!!`,msg:`Tu as oublié de parier sur des matchs ou de mettre un bonus !`});
            setOpen(true);
        }

    };

    function verifBonusParier() {
        if (droitJouerBombeLive && nbBombeJouerLive !== 0 ) {
            return false;
        }
        if (droitJouerBananeLive && nbFireLive !== 0) {
            return false;
        }
        if (droitJouerCeriseLive && nbCeriseJouerLive !== 0) {
            return false;
        }
        if (droitJouerGrappaLive && nbGrappaJouerLive !== 0) {
            return false;
        }
        return true;
    }

    const handleChangeBonus = (sens, droitBonus, nbBonus, nbBonusMax) => {
        if (sens === '+') {
            if (droitBonus && nbBonus < nbBonusMax) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    const handleClickOpenNotif = () => {
        webPush.subscribe(context.user);
        setOpenNotif(false);
    };

    const handleCloseCancelNotif = () => {
        setOpenNotif(false);
    };

    const handleCloseCancel = () => {
        setOpen(false);
    };

    return (
        <AppbarNav>
            <ThemeProvider theme={Theme}>
                <Container
                    maxWidth="sm"
                    sx={{marginBottom: 2}}
                >
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <Typography>Date limite validation : {dateValide}</Typography>
                        <FormControl fullWidth>
                            <InputLabel id="select-label-journees">Journées</InputLabel>
                            <Select
                                labelId="select-label-journees"
                                id="select-journees"
                                value={journeeSelected}
                                label="Journées"
                                size="small"
                                onChange={(e) => {
                                    setJourneeSelected(e.target.value)
                                }}
                            >
                                {allJournees}
                            </Select>
                        </FormControl>
                        <Box>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <div style={{'marginRight':'10px'}}>Tes bonus  </div>
                                <div>
                                    <ClickAwayListener onClickAway={handleTooltipClose}>
                                        <Tooltip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            onClose={handleTooltipClose}
                                            open={openToolTip}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title="La bomba te permet de faire perdre 5 points à toutes les personnes qui se sont trompées sur le pari. Attention cela peut se retourner contre toi !!!"
                                        >
                                            <div style={grenadeBackground} onClick={handleTooltipOpen}></div>
                                        </Tooltip>
                                
                                    </ClickAwayListener>
                                    
                                </div>
                                
                                <div> X {droitJouerBombeLive ? nbBombeJouerLive : 0}</div>

                                <div>
                                    <ClickAwayListener onClickAway={handleTooltipCloseCerise}>
                                        <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={handleTooltipCloseCerise}
                                                open={openToolTipCerise}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="La Cerise te donne une seconde chance pour un pari. Tu peux par exemple parier sur une victoire et un match nul. Tu doubles tes chances de gagner des points !"
                                            >
                                            <div style={ceriseBackground} onClick={handleTooltipOpenCerise}></div>
                                        </Tooltip>
                                    </ClickAwayListener>                          
                                </div>
                                
                                <div> X {droitJouerCeriseLive ? nbCeriseJouerLive : 0}</div>
                                <div>
                                <ClickAwayListener onClickAway={handleTooltipCloseFire}>
                                <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={handleTooltipCloseFire}
                                                open={openToolTipFire}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="Le onFIRE ! Tu enflammes un match et les foules ! Tu ajoutes 4 points à tous les paris justes."
                                            >
                                                <Whatshot sx={{height: 40, width: 38, color: '#ffff1a'}} onClick={handleTooltipOpenFire}></Whatshot>
                                            </Tooltip>
                                    
                                </ClickAwayListener>
                                    
                                </div>
                                
                                <div> X {droitJouerBananeLive ? nbFireLive : 0}</div>

                                <div>
                                <ClickAwayListener onClickAway={handleTooltipCloseGrappa}>
                                <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={handleTooltipCloseGrappa}
                                                open={openToolTipGrappa}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="La GRAPPA te permet de gagner 2 points supplémentaires par but marqué SI tu as bien parié."
                                            >
                                                <div style={grappaBackground} onClick={handleTooltipOpenGrappa}></div>
                                            </Tooltip>
                                    
                                </ClickAwayListener>
                                    
                                </div>
                                
                                <div> X {droitJouerGrappaLive ? nbGrappaJouerLive : 0}</div>
                            </Stack>
                        </Box>
                        {loading
                            ? <CircularProgress></CircularProgress>
                            : matchsIHM}
                        {!loading &&
                        <Button variant="contained" onClick={handleClickValider}>Valider les paris</Button>}
                    </Stack>

                    <Dialog open={open} onClose={handleCloseCancel}>
                        <DialogTitle>{infoParis.titre}</DialogTitle>
                        <DialogContent>
                            {infoParis.msg}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCancel}>Annuler</Button>
                            <Button onClick={handleCloseCancel}>Valider</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={openNotif} onClose={handleCloseCancelNotif}>
                        <DialogTitle>Informations</DialogTitle>
                        <DialogContent>
                            Recevoir les notifications ?
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCancelNotif}>Annuler</Button>
                            <Button onClick={handleClickOpenNotif}>Valider</Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </ThemeProvider>
        </AppbarNav>
    );
}


