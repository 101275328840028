import React, {useEffect} from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import {styled} from "@mui/material/styles";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Grid from "@mui/material/Grid";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from "@mui/material/Typography";
import Grenade from '../assets/grenade.png';
import AnanasGolden from '../assets/medaille_ananas.png';

export default function LigneClassementGeneral(props) {

    const grenadeBackground = {
        backgroundImage: `url(${Grenade})`,
        width: '38px',
        height: '41px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    };

    const ananasBackground = {
        backgroundImage: `url(${AnanasGolden})`,
        width: '40px',
        height: '40px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        marginTop: '-31px',
        backgroundSize: '40px',
        position: 'absolute',
        marginLeft: '-23px',
        transform: 'rotate(-30deg)'
    };

    const {row} = props;

    const [openToolTip, setOpenToolTip] = React.useState(false);
    const [progressionPlacement, setProgressionPlacement] = React.useState('');


    useEffect(() => {
        if (row.data.placementProgression == 0) {
            setProgressionPlacement('');
        } else if (row.data.placementProgression > 0) {
            setProgressionPlacement(
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <ArrowDropUpIcon fontSize="small" sx={{
                            color: "#38b013",
                            marginTop: '4px'
                        }}></ArrowDropUpIcon>
                    </Grid>
                    <Grid item>
                        <Typography sx={{
                            color: "#38b013",
                            fontSize: "11px",
                        }}>
                            {row.data.placementProgression}
                        </Typography>
                    </Grid>
                </Grid>
            );
        } else {
            setProgressionPlacement(
                <Grid container direction="row" alignItems="center">
                    <Grid item>
                        <ArrowDropDownIcon fontSize="small" sx={{
                            color: "#f00",
                            marginTop: '4px'
                        }}></ArrowDropDownIcon>
                    </Grid>
                    <Grid item>
                        <Typography sx={{
                            color: "#f00",
                            fontSize: "11px",
                        }}>
                            {Math.abs(row.data.placementProgression)}
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
    }, []);

    const handleTooltipClose = () => {
        setOpenToolTip(false);
    };

    const handleTooltipOpen = () => {
        setOpenToolTip(true);
    };

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 11,
            wordBreak: 'break-all',
            verticalAlign: 'middle',
            padding: '1px',
            paddingBottom: '16px',
            paddingTop: '16px',
            width:'10%'
        },
    }));


    const StyledTableCellPosition = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 11,
            wordBreak: 'break-all',
            verticalAlign: 'middle',
            padding: '1px',
            paddingLeft: '5px',
            paddingBottom: '16px',
            paddingTop: '16px'
        },
    }));

    const StyledTableCellUser = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
            wordBreak: 'break-all',
            verticalAlign: 'middle',
            padding: '2px',
            paddingBottom: '16px',
            paddingTop: '16px'
        },
    }));


    return (
        <React.Fragment>
            <TableRow key={row.data.username}
                      sx={{backgroundColor: props.couleurBack}}>
                <StyledTableCellPosition align="center">
                    <div style={{width: '40px'}}>
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                {row.num}
                            </Grid>
                            <Grid item>
                                {progressionPlacement}
                            </Grid>
                        </Grid>
                    </div>
                </StyledTableCellPosition>
                <StyledTableCellUser
                    align="center">
                    <div >
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <div>
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={openToolTip}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={props.diffPts}
                                >
                                    <Button sx={{color: 'black', fontSize: 12, padding: 0}}
                                            onClick={handleTooltipOpen}>{row.data.username.toUpperCase()}</Button>
                                </Tooltip>
                                {row.titre &&
                                    <div style={ananasBackground} onClick={handleTooltipOpen}></div>
                                }
                                
                            </div>
                        </ClickAwayListener>
                    </div>
                </StyledTableCellUser>
                <StyledTableCell align="center">{row.data.total}</StyledTableCell>
                <StyledTableCell
                    align="center">{Math.round(+row.data.pourcentages * 100)} %</StyledTableCell>
                <StyledTableCell align="center">{row.data.mok}</StyledTableCell>
                <StyledTableCell align="center">{row.data.progression}</StyledTableCell>
            </TableRow>
        </React.Fragment>
    );
}
