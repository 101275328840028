import React, {useEffect} from "react";
import AppbarNav from "./AppbarNav";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from '@mui/material/TableBody';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Stack from "@mui/material/Stack";
import ClassementJournees from "./ClassementJournees";
import ClassementGeneral from "./ClassementGeneral";

export default function Resultats() {
    const [alignment, setAlignment] = React.useState('journee');


    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    return (
        <AppbarNav>
            <Container maxWidth="md">
                <Stack
                    direction="column"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                >


                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                    >
                        <ToggleButton value="journee">Journée</ToggleButton>
                        <ToggleButton value="gene">Général</ToggleButton>
                    </ToggleButtonGroup>
                    {alignment == 'journee' ? <ClassementJournees/> : <ClassementGeneral/>}

                </Stack>
            </Container>
        </AppbarNav>
    );
}
