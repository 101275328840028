import React, {useEffect, useState} from "react";
import {styled} from '@mui/material/styles';
import AppbarNav from "./AppbarNav";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from "@mui/material/Container";
import axios from "axios";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import fire from '../assets/fire.png';
import Grenade from '../assets/grenade.png';
import LigneGrille from "./LigneGrille";

export default function Grille() {
    const [grille, setGrille] = React.useState([]);
    const [grilleLettres, setGrilleLettres] = React.useState([]);
    const [grillePseudos, setGrillePseudos] = React.useState([]);
    const [journeeSelected, setJourneeSelected] = React.useState('');
    const [allJournees, setAllJournees] = React.useState(<MenuItem></MenuItem>);



    useEffect(() => {
        axios.get('/api/journees/findAllGrille').then((res) => {
            if (res.data.length) {
                setAllJournees(res.data.map((j) =>
                    <MenuItem key={j.numero_journee} value={j.numero_journee}>{j.numero_journee}</MenuItem>
                ));
                setJourneeSelected(Math.max(...res.data.map(j => j.numero_journee)));
            }

        }).catch((error) => {
            console.error(error);
        });
    }, []);


    useEffect(() => {
        if (journeeSelected) {
            axios.get('/api/rencontres/findByJournees', {params: {numero: journeeSelected}}).then((res) => {
                const tabLettre = [];
                const tabPseudos = [];
                axios.get('/api/parier/lettreParier', {params: {journees: journeeSelected}}).then((resL) => {
                    res.data.forEach(p => {
                        const dom = resL.data.filter(d => (d.valeurParis == '1' || d.cerise == '1') && d.idrencontres == p.id_rencontres).map(dmap => dmap.lettre).sort((a, b) => a.localeCompare(b)).toString().toUpperCase();
                        const nul = resL.data.filter(d => (d.valeurParis == 'N' || d.cerise == 'N') && d.idrencontres == p.id_rencontres).map(dmap => dmap.lettre).sort((a, b) => a.localeCompare(b)).toString().toUpperCase();
                        const ext = resL.data.filter(d => (d.valeurParis == '2' || d.cerise == '2') && d.idrencontres == p.id_rencontres).map(dmap => dmap.lettre).sort((a, b) => a.localeCompare(b)).toString().toUpperCase();
                        tabLettre[p.id_rencontres] = {dom: dom, nul: nul, ext: ext};
                    });
                    res.data.forEach(p => {
                        const dom = resL.data.filter(d => (d.valeurParis == '1' || d.cerise == '1') && d.idrencontres == p.id_rencontres).map(dmap => ` ${dmap.username}`).sort((a, b) => a.localeCompare(b)).toString().toUpperCase();
                        const nul = resL.data.filter(d => (d.valeurParis == 'N' || d.cerise == 'N') && d.idrencontres == p.id_rencontres).map(dmap => ` ${dmap.username}`).sort((a, b) => a.localeCompare(b)).toString().toUpperCase();
                        const ext = resL.data.filter(d => (d.valeurParis == '2' || d.cerise == '2') && d.idrencontres == p.id_rencontres).map(dmap => ` ${dmap.username}`).sort((a, b) => a.localeCompare(b)).toString().toUpperCase();
                        tabPseudos[p.id_rencontres] = {dom: dom, nul: nul, ext: ext};
                    });
                    setGrillePseudos(tabPseudos);
                    setGrilleLettres(tabLettre);
                    setGrille(res.data);
                });

            });
        }

    }, [journeeSelected]);

/*
    const getLettre = (idrencontres, type) => {
        axios.get('/api/parier/lettreParier', {params: {idrencontres: idrencontres}}).then((resL) => {
            return resL.data.filter(d => d.valeurParis == type || d.cerise == type).map(dmap => dmap.lettre).toString().toUpperCase();
        });
    };

    const definirQuiGagne = (quiAppel, info) => {
        if (quiAppel === 'dom') {
            return info.but_dom > info.but_ext ? '#aaff80' : '';
        } else if (quiAppel === 'nul') {
            return info.but_dom === info.but_ext ? '#aaff80' : '';
        } else if (quiAppel === 'ext') {
            return info.but_dom < info.but_ext ? '#aaff80' : '';
        }

    };

 */


    return (
        <AppbarNav>
            <FormControl fullWidth>
                <InputLabel id="select-label-journees">Journées</InputLabel>
                <Select
                    labelId="select-label-journees"
                    id="select-journees"
                    value={journeeSelected}
                    label="Journées"
                    size="small"
                    onChange={(e) => {
                        setJourneeSelected(e.target.value)
                    }}
                >
                    {allJournees}
                </Select>
            </FormControl>
            {grille.map((row) => (
                <LigneGrille row={row} grilleLettres={grilleLettres} grillePseudos={grillePseudos} key={row.id_rencontres}/>
            ))}


        </AppbarNav>
    );
}


/*
                    <TableContainer component={Paper} sx={{marginTop: 3}}>
                        <Table>
                            <TableHead>
                                <TableRow sx={{backgroundColor: '#e6b3ff'}}>
                                    <TableCell>
                                        <Typography fontSize={11} sx={{fontWeight: 'bold'}}>
                                            J{journeeSelected}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography fontSize={11} sx={{fontWeight: 'bold'}}>
                                            1
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography fontSize={11} sx={{fontWeight: 'bold'}}>
                                            N
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography fontSize={11} sx={{fontWeight: 'bold'}}>
                                            2
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography fontSize={11} sx={{fontWeight: 'bold'}}>
                                            Bombes
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {grille.map((row) => (
                                    <TableRow
                                        key={row.id_rencontres}
                                        sx={{
                                            '&:last-child td, &:last-child th': {border: 0},
                                            backgroundColor: row.nbonfire ? '#ff6666' : ''
                                        }}
                                    >
                                        <TableCell component="th" scope="row" align="center">
                                            <Typography fontSize={9}>
                                                {row.but_dom} {row.equipes.abbreviation}
                                                <br/>-<br/> {row.but_ext} {row.equipesExt.abbreviation}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center"
                                                   sx={{backgroundColor: definirQuiGagne('dom', row)}}>
                                            <Box>
                                                {row.pts_dom ? row.pts_dom : '-'}
                                                <br/>
                                                <hr/>
                                                <Typography fontSize={9}>
                                                    {grilleLettres[row.id_rencontres]?.dom}
                                                </Typography>
                                            </Box>

                                        </TableCell>
                                        <TableCell align="center"
                                                   sx={{backgroundColor: definirQuiGagne('nul', row)}}>
                                            <Box>
                                                {row.pts_nul ? row.pts_nul : '-'}
                                                <br/>
                                                <hr/>
                                                <Typography fontSize={9}>
                                                    {grilleLettres[row.id_rencontres]?.nul}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="center"
                                                   sx={{backgroundColor: definirQuiGagne('ext', row)}}>
                                            <Box>
                                                {row.pts_ext ? row.pts_ext : '-'}
                                                <br/>
                                                <hr/>
                                                <Typography fontSize={9}>
                                                    {grilleLettres[row.id_rencontres]?.ext}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell align="right">
                                            <Stack
                                                direction={row}
                                                spacing={1}
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                {row.nbbombes}&nbsp;x&nbsp;<Volcano sx={{marginBottom: 1}}
                                                                                    fontSize="small"></Volcano>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
 */
