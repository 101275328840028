import React from 'react';

export const AuthContext = React.createContext({
    signin: () => {
    },
    signout: () => {

    },
    user: null
});
