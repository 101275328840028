import React from 'react';
import Stack from '@mui/material/Stack';
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { ReactComponent as Logo } from '../assets/logoV7.svg'

export default class ConnexionContainer extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {

        return (
            <Container maxWidth="sm">
                <Box sx={{my: 4}}>
                    <div>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Logo/>
                            {this.props.children}
                        </Stack>
                    </div>
                </Box>
            </Container>

        );
    }
}
