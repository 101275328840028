import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from "@mui/material/Grid";
import {DialogContent} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import axios from "axios";


export default function CardRencontre(props) {
    const [open, setOpen] = React.useState(false);
    const [butDom, setButDom] = React.useState(props.butDom);
    const [butExt, setButExt] = React.useState(props.butExt);
    const [reporte, setReporte] = React.useState(props.reporte);

    const datePipe = (date) => {
        let dateD = new Date(date);
        return `${dateD.toLocaleDateString()} à ${dateD.toLocaleTimeString()}`;
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseCancel = () => {
        setOpen(false);
    };

    const handleCloseSubmit = () => {
      axios.delete('/api/rencontres/delete',{params:{idRencontre:props.idRencontre}}).then((res) => {
          setOpen(false);
          props.appel(props.numJournee);
      }).catch((error) => {
         alert('Erreur lors de la suppression.');
      });
    };

    const handleChangeButDom = (event) => {
        if (event.target.value) {
            setButDom(event.target.value);
        }
    };

    const handleChangeButExt = (event) => {
        if (event.target.value) {
            setButExt(event.target.value);
        }
    };

    const handleChangeReporte = (event) => {
        setReporte(event.target.checked);
    }

    useEffect(() => {
        axios.put('/api/rencontres/put',null,{params:{idRencontre:props.idRencontre, butDom:butDom, butExt:butExt, reporte: reporte}}).then((res) => {
            console.log(res);
        }).catch((error) => {
            console.error(error);
        });
    },[butDom, butExt, reporte]);


    return (
        <Container maxWidth="sm">
            <Card sx={{paddingBottom: "10px"}}>
                <CardContent>
                    <Stack direction="column" spacing={1}
                    >
                        <Typography component="div" sx={{fontSize: 15}}>
                            {datePipe(props.dateRencontre)}
                        </Typography>
                        <Box>
                            <Box sx={{float: "left"}}>
                                <Stack direction="column" spacing={1} justifyContent="center"
                                       alignItems="center">
                                    <Typography variant="h5" component="div" sx={{fontSize: 20}}>
                                        Domicile
                                    </Typography>
                                    <Typography component="div" sx={{fontSize: 15}}>
                                        {props.equipeDom}
                                    </Typography>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        size="small"
                                        defaultValue={butDom}
                                        onChange={handleChangeButDom}
                                        sx={{width: "40px"}}
                                    />
                                </Stack>
                            </Box>
                            <Box sx={{float: "right"}}>
                                <Stack direction="column" spacing={1} justifyContent="center"
                                       alignItems="center">
                                    <Typography variant="h5" component="div" sx={{fontSize: 20}}>
                                        Extérieur
                                    </Typography>
                                    <Typography component="div" sx={{fontSize: 15}}>
                                        {props.equipeExt}
                                    </Typography>
                                    <TextField id="outlined-basic"
                                               variant="outlined"
                                               size="small"
                                               defaultValue={butExt}
                                               onChange={handleChangeButExt}
                                               sx={{width: "40px"}}
                                    />
                                </Stack>
                            </Box>
                        </Box>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={10}>
                                    <FormGroup>
                                        <FormControlLabel control={<Switch/>} label="Reporté / pas commencé" checked={reporte} onChange={handleChangeReporte}/>
                                    </FormGroup>
                                </Grid>
                                <Grid item xs={2}>
                                    <label htmlFor="icon-button-file" onClick={handleClickOpen}>
                                        <IconButton color="primary" aria-label="upload picture" component="span">
                                            <DeleteIcon />
                                        </IconButton>
                                    </label>
                                </Grid>
                            </Grid>


                        </Box>
                    </Stack>
                </CardContent>
            </Card>
            <Dialog open={open} onClose={handleCloseCancel}>
                <DialogTitle>Supprimer</DialogTitle>
                <DialogContent>
                    Voulez-vous supprimer la rencontre {props.equipeDom} - {props.equipeExt} ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCancel}>Annuler</Button>
                    <Button onClick={handleCloseSubmit}>Valider</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}
