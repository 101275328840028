import React, {useEffect} from "react";
import AppbarNav from "./AppbarNav";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Fab from "@mui/material/Fab";
import AddIcon from '@mui/icons-material/Add';
import {Theme} from "./theme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import CardRencontre from "./CardRencontre";
import {DateTimePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {createFilterOptions} from "@mui/base";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";


export default function Admin() {
    const [journeeSelected, setJourneeSelected] = React.useState("");
    const [equipes, setEquipes] = React.useState([]);
    const [allJournees, setAllJournees] = React.useState(<MenuItem></MenuItem>);
    const [open, setOpen] = React.useState(false);
    const [rencontres, setRencontres] = React.useState(null);
    const [valueDate, setValueDate] = React.useState(new Date());
    const [equipeDom, setEquipeDom] = React.useState('');
    const [equipeExt, setEquipeExt] = React.useState('');
    const [afficherJournee, setAfficherJournee] = React.useState(false);
    const [afficherGrille, setAfficherGrille] = React.useState(false);
    const [allJourneesBrut, setAllJourneesBrut] = React.useState(null);


    const OPTIONS_LIMIT = 1;
    const filterOptions = createFilterOptions({
        limit: OPTIONS_LIMIT
    });

    const handleChangeDate = (newValue) => {
        setValueDate(newValue);
    };

    const handleChange = (event) => {
        setJourneeSelected(event.target.value);
        appelRencontresJournee(event.target.value);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleCloseCancel = () => {
        setOpen(false);
    };

    const handleCloseSubmit = () => {

        if (equipeDom !== '' && equipeExt !== '' && journeeSelected !== '') {
            valueDate.setSeconds(0);
            axios.post('/api/rencontres/create',null,{params:{date:valueDate.toLocaleString(),equipeDom:equipeDom, equipeExt: equipeExt, numero: journeeSelected}}).then( (res) => {
                setOpen(false);
                appelRencontresJournee(journeeSelected);
            }).catch((e) => {
                alert("Erreur dans l'appel du WS");
            });

        } else {
            alert("Erreur lors de la création de la rencontre");
        }
    };

    const handleChangeEquipeDom = (e) => {
        setEquipeDom(e.target.textContent);
    };

    const handleChangeEquipeExt = (e) => {
        setEquipeExt(e.target.textContent);
    };

    const appelRencontresJournee = (num) => {
        const journee = allJourneesBrut.find(c => c.numero_journee == num);
        if (journee) {
            setAfficherJournee(journee.afficher);
            setAfficherGrille(journee.grille);
        }
        axios.get('/api/rencontres/findByJournees', {params: {numero: num}}).then((res) => {
            setRencontres(res.data.map((r) =>
                <CardRencontre key={r.id_rencontres} equipeDom={r.equipes.abbreviation}
                               equipeExt={r.equipesExt.abbreviation} butDom={r.but_dom}
                               butExt={r.but_ext} dateRencontre={r.date} numJournee={r.journees.numero_journee} appel={appelRencontresJournee} reporte={r.reporte} idRencontre={r.id_rencontres}> </CardRencontre>
            ));
            axios.get('/api/equipes/findAllNotSelected', {params: {numero: num}}).then((res) => {
                setEquipes(res.data.map((j) => j["nomclub"]));
            }).catch((error) => {
                console.error(error);
            });
        }).catch((error) => {
            console.error(error);
        });
    };

    const handleCreateJournee = () => {
      axios.get('/api/journees/createJourneeMax').then((res) => {
          setJourneeSelected(res.data.numero_journee);
          appelRencontresJournee(res.data.numero_journee);
          findAllJournees();
      }).catch( (error) => {
         alert('Problème lors de la création de la journée');
         console.error(error);
      });
    };

    const handleChangeAfficherJournee = (event) => {
        setAfficherJournee(event.target.checked);
        axios.get('/api/journees/afficher',{params:{numJournee: journeeSelected, afficher: event.target.checked}}).then((res) => {
            console.log('ok');
        }).catch(() => {
            console.error('erreur');
        })
    };

    const handleChangeAfficherGrille = (event) => {
        setAfficherGrille(event.target.checked);
        axios.get('/api/journees/afficherGrille',{params:{numJournee: journeeSelected, grille: event.target.checked}}).then((res) => {
            console.log('ok');
        }).catch(() => {
            console.error('erreur');
        })
    };

    const findAllJournees = () => {
        axios.get('/api/journees/findAll').then((res) => {
            setAllJourneesBrut(res.data);
            setAllJournees(res.data.map((j) =>
                <MenuItem key={j.numero_journee} value={j.numero_journee}>{j.numero_journee}</MenuItem>
            ));
        }).catch((error) => {
            console.error(error);
        });
    };

    useEffect(() => {
        findAllJournees();
    }, []);
    return (
        <AppbarNav>
            <ThemeProvider theme={Theme}>
                <Stack direction="column"
                       justifyContent="center"
                       alignItems="center"
                       spacing={2}>
                    <Container maxWidth="sm">
                        <Stack direction="row" spacing={{xs: 1, sm: 2, md: 4}} justifyContent="center"
                               alignItems="center">
                            <FormControl fullWidth>

                                <InputLabel id="select-label-journees">Journées</InputLabel>
                                <Select
                                    labelId="select-label-journees"
                                    id="select-journees"
                                    value={journeeSelected}
                                    label="Journées"
                                    onChange={handleChange}
                                    size="small"
                                >
                                    {allJournees}
                                </Select>

                            </FormControl>
                            <Fab color="primary" aria-label="add" size="small" onClick={handleCreateJournee}>
                                <AddIcon/>
                            </Fab>
                        </Stack>
                    </Container>
                    <Container maxWidth="xl">
                        { journeeSelected !== "" &&
                        <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Fab variant="extended" size="small" color="primary" aria-label="add" onClick={handleClickOpen}>
                                <AddIcon sx={{mr: 1}}/>
                                Créer rencontre
                            </Fab>
                            <FormGroup>
                                <FormControlLabel control={<Switch/>} label="Afficher journée" checked={afficherJournee} onChange={handleChangeAfficherJournee}/>
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Switch/>} label="Afficher grille" checked={afficherGrille} onChange={handleChangeAfficherGrille}/>
                            </FormGroup>
                        </Stack>


                        }

                    </Container>
                    {rencontres}
                </Stack>

                <Dialog open={open} onClose={handleCloseCancel}>
                    <DialogTitle>Rencontre</DialogTitle>
                    <DialogContent>
                        <Stack spacing={1} sx={{marginTop: '10px'}}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Date&Time picker"
                                    value={valueDate}
                                    onChange={handleChangeDate}
                                    ampm={false}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            <Stack direction="row" spacing={1} sx={{paddingBottom: '50px'}}>
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={equipes}
                                    sx={{width: 200}}
                                    filterOptions={filterOptions}
                                    size="small"
                                    onChange={handleChangeEquipeDom}
                                    renderInput={(params) => <TextField {...params} label="Domicile"/>}
                                />
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={equipes}
                                    sx={{width: 200}}
                                    filterOptions={filterOptions}
                                    size="small"
                                    onChange={handleChangeEquipeExt}
                                    renderInput={(params) => <TextField {...params} label="Extérieur"/>}
                                />
                            </Stack>

                        </Stack>


                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseCancel}>Annuler</Button>
                        <Button onClick={handleCloseSubmit}>Valider</Button>
                    </DialogActions>
                </Dialog>
            </ThemeProvider>
        </AppbarNav>
    );
}
