import React, {useContext} from 'react';
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from '@mui/material/MenuItem';
import Menu from "@mui/material/Menu";
import MenuIcon from '@mui/icons-material/Menu';
import Button from "@mui/material/Button";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import {Link} from "react-router-dom";
import {AuthContext} from "./auth-context";
import {Theme} from "./theme";
import { ReactComponent as Logo } from '../assets/logoV7.svg';
import croix from '../assets/Croix_occitane.png';

//const pages = [{url:'accueil', nom: 'Acceuil'}, {url:'resultats', nom: 'Résultats'}];
const settings = [];
const theme = Theme;

export default function AppbarNav(props) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    let context = useContext(AuthContext);
    let pages = [{url: 'accueil', nom: 'Mes pronos'}, {url: 'grille', nom: 'Live'},{url: 'resultats', nom: 'Classements'}];
    if (context.user.roles.findIndex(c => c.authority == "ROLE_ADMIN") >= 0) {
        pages.push({url: 'admin', nom: 'Admin'});
    }

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    return (
        <Box>
            <ThemeProvider theme={theme}>
                <AppBar position='static' color='primary' sx={{backgroundImage: `url(${croix})`}}>
                    <Container maxWidth={false}>
                        <Toolbar
                            disableGutters
                        >
                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}
                            >
                                <Logo/>
                            </Typography>
                            <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon/>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: {xs: 'block', md: 'none'},
                                    }}
                                >
                                    {pages.map((page) => (

                                        <MenuItem key={page.url} onClick={handleCloseNavMenu} component={Link}
                                                  to={"/" + page.url}>

                                            <Typography textAlign="center">{page.nom}</Typography>
                                        </MenuItem>

                                    ))}
                                </Menu>
                            </Box>

                            <Typography
                                variant="h6"
                                noWrap
                                component="div"
                                sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}
                            >
                                <Logo/>
                            </Typography>
                            <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                                {pages.map((page) => (
                                    <Button
                                        key={page.url}
                                        onClick={handleCloseNavMenu}
                                        sx={{my: 2, color: 'white', display: 'block'}}
                                        component={Link}
                                        to={"/" + page.url}
                                    >
                                        {page.nom}
                                    </Button>
                                ))}
                            </Box>
                            <Box sx={{flexGrow: 0, width:40}}>

                            </Box>

                        </Toolbar>
                    </Container>
                </AppBar>
            </ThemeProvider>
            <Container maxWidth="xl">
                <Box sx={{marginTop:'30px'}}>
                    {props.children}
                </Box>
            </Container>
        </Box>
    );

}

/* Account menu fin
                            <Box sx={{flexGrow: 0}}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                        <Avatar alt="Remy Sharp" src=""/>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{mt: '45px'}}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => (
                                        <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                            <Typography textAlign="center">{setting}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
 */
