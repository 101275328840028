import React, {useEffect} from 'react';
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {Volcano, VolunteerActivism, Whatshot} from "@mui/icons-material";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import CeriseV2 from '../assets/CerisesV2.png';
import Grenade from '../assets/grenade.png';
import Grappa from '../assets/grappa.png';


export default function CardPari(props) {

    const [choixMatch, setChoixMatch] = React.useState('');
    const [choixCerise, setChoixCerise] = React.useState('');
    const [bombe, setBombe] = React.useState(false);
    const [grappa, setGrappa] = React.useState(false);
    const [onFire, setOnFire] = React.useState(false);
    const [grenadeBackground, setGrenadeBackground] = React.useState({
        backgroundImage: `url(${Grenade})`,
        width: '38px',
        height: '41px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    });
    const [ceriseBackground, setCeriseBackground] = React.useState({
        backgroundImage: `url(${CeriseV2})`,
        width: '30px',
        height: '30px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    });
    const [fireBackground, setFireBackground] = React.useState({
        height: 40,
        width: 38
    });
    const [grappaBackground, setGrappaBackground] = React.useState({
        backgroundImage: `url(${Grappa})`,
        width: '38px',
        height: '41px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    });


    // TODO - Objet paris à remplir avec soit les informations si présentes de la props sinon à vide
    // TODO - Vérifier si l'utilisateur peut utilise les options supplémentaires de paris

    const handleChange = (event, newAlignment) => {
        props.handleChangeParis(newAlignment, props.match.id_rencontres.toString());
        setChoixMatch(newAlignment);
    };

    const handleChangeCerise = (event, newChoixCerise) => {
        if (newChoixCerise) {
            if (props.handleChangeCerise(newChoixCerise, props.match.id_rencontres.toString(), '+')) {
                setChoixCerise(newChoixCerise);
            }
        } else {
            props.handleChangeCerise(newChoixCerise, props.match.id_rencontres.toString(), '-');
            setChoixCerise(newChoixCerise);
        }
    };

    useEffect(() => {
        let pariFind = props.pari.find(p => p.idRencontres === props.match.id_rencontres.toString());
        if (pariFind) {
            setChoixMatch(pariFind.valeurParis);
            setChoixCerise(pariFind.cerise);
            setOnFire(pariFind.onfire ? true : false);
            setBombe(pariFind.bombe ? true : false);
            setGrappa(pariFind.grappa ? true : false);
        }

        if (!props.droitJouerBombe) {
            setGrenadeBackground({
                backgroundImage: `url(${Grenade})`,
                width: '38px',
                height: '41px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                filter: 'grayscale(100%)'
            });
        } else {
            setGrenadeBackground({
                backgroundImage: `url(${Grenade})`,
                width: '38px',
                height: '41px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                filter: 'none'
            });
        }

        if (!props.droitJouerGrappa) {
            setGrappaBackground({
                backgroundImage: `url(${Grappa})`,
                width: '38px',
                height: '41px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                filter: 'grayscale(100%)'
            });
        } else {
            setGrappaBackground({
                backgroundImage: `url(${Grappa})`,
                width: '38px',
                height: '41px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                filter: 'none'
            });
        }

        if (!props.droitJouerCerise) {
            setCeriseBackground({
                backgroundImage: `url(${CeriseV2})`,
                width: '30px',
                height: '30px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                filter: 'grayscale(100%)'
            });
        } else {
            setCeriseBackground({
                backgroundImage: `url(${CeriseV2})`,
                width: '30px',
                height: '30px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
            });
        }
        if (!props.droitJouerBanane) {
            setFireBackground({
                height: 40,
                width: 38
            });
        } else {
            setFireBackground({
                height: 40,
                width: 38,
                color: '#ffff1a'
            });
        }
    }, []);

    useEffect(() => {
        if (props.match.journees.numero_journee == 1) {
            setChoixCerise(false);
        }
    }, [choixCerise]);


    return (
        <Card sx={{minHeight: 100, padding: 1}}>
            <Stack
                direction="row"
                spacing={1}
                justifyContent="center"
                alignItems="center"
            >
                <Box>
                    <Stack
                        direction="column"
                        spacing={1}
                    >
                        <ToggleButtonGroup
                            color="primary"
                            value={choixMatch}
                            exclusive
                            onChange={handleChange}
                        >
                            <ToggleButton value="1"
                                          sx={{width: '80px'}}>{props.match.equipes.abbreviation}</ToggleButton>
                            <ToggleButton value="N" sx={{width: '80px'}}>NUL</ToggleButton>
                            <ToggleButton value="2"
                                          sx={{width: '80px'}}>{props.match.equipesExt.abbreviation}</ToggleButton>
                        </ToggleButtonGroup>

                        <ToggleButtonGroup
                            color="primary"
                            value={choixCerise}
                            exclusive
                            onChange={handleChangeCerise}
                        >
                            <ToggleButton
                                value="1"
                                disabled={!props.droitJouerCerise}
                                sx={{width: '80px'}}
                            >
                                <div style={ceriseBackground}></div>
                            </ToggleButton>
                            <ToggleButton
                                value="N"
                                disabled={!props.droitJouerCerise}
                                sx={{width: '80px'}}
                            >
                                <div style={ceriseBackground}></div>
                            </ToggleButton>
                            <ToggleButton
                                value="2"
                                disabled={!props.droitJouerCerise}
                                sx={{width: '80px'}}
                            >
                                <div style={ceriseBackground}></div>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </Box>
                <Box>
                    <ToggleButton
                        value="check"
                        selected={onFire}
                        disabled={!props.droitJouerBanane}
                        onChange={() => {
                            if (onFire) {
                                props.handleChangeBanane('-', props.match.id_rencontres.toString());
                                setOnFire(!onFire);
                            } else {
                                if (props.handleChangeBanane('+', props.match.id_rencontres.toString())) {
                                    setOnFire(!onFire);
                                }
                            }
                        }}
                        sx={{border: 'none'}}
                    >
                        <Whatshot sx={fireBackground}></Whatshot>
                    </ToggleButton>

                    <ToggleButton
                        value="check"
                        selected={bombe}
                        disabled={!props.droitJouerBombe}
                        onChange={() => {
                            if (bombe) {
                                props.handleChangeBombe('-', props.match.id_rencontres.toString());
                                setBombe(!bombe);
                            } else {
                                if (props.handleChangeBombe('+', props.match.id_rencontres.toString())) {
                                    setBombe(!bombe);
                                }
                            }
                        }}
                        sx={{border: 'none'}}
                    >


                        <div style={grenadeBackground}></div>
                    </ToggleButton>

                    <ToggleButton
                        value="check"
                        selected={grappa}
                        disabled={!props.droitJouerGrappa}
                        onChange={() => {
                            if (grappa) {
                                props.handleChangeGrappa('-', props.match.id_rencontres.toString());
                                setGrappa(!grappa);
                            } else {
                                if (props.handleChangeGrappa('+', props.match.id_rencontres.toString())) {
                                    setGrappa(!grappa);
                                }
                            }
                        }}
                        sx={{border: 'none'}}
                    >

                        <div style={grappaBackground}></div>
                    </ToggleButton>
                </Box>
            </Stack>
        </Card>
    );

}
