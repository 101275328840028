import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import {styled} from "@mui/material/styles";

export default function ClassementJournees() {
    const [journeeSelected, setJourneeSelected] = React.useState('');
    const [allJournees, setAllJournees] = React.useState(<MenuItem></MenuItem>);
    const [classJournee, setClassJournee] = React.useState([]);

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 11,
            wordBreak: 'break-all',
            verticalAlign: 'middle',
            width: '25%',
            padding: '5px',
            paddingBottom: '16px',
            paddingTop: '16px'
        },
    }));

    const StyledTableCellUser = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 12,
            wordBreak: 'break-all',
            verticalAlign: 'middle',
            width: '100%',
            padding: '5px',
            paddingBottom: '16px',
            paddingTop: '16px'
        },
    }));

    useEffect(() => {
        axios.get('/api/journees/findAllGrille').then((res) => {
            if (res.data.length) {
                setAllJournees(res.data.map((j) =>
                    <MenuItem key={j.numero_journee} value={j.numero_journee}>{j.numero_journee}</MenuItem>
                ));
                setJourneeSelected(Math.max(...res.data.map(j => j.numero_journee)));
            }

        }).catch((error) => {
            console.error(error);
        });
    }, []);

    useEffect(() => {

        if (journeeSelected) {
            axios.get('/api/classement/journee', {params: {journee: journeeSelected}}).then((res) => {
                const newTab = [];
                res.data.forEach((c, index) => {
                    if (index == 0) {
                        newTab.push({data: c, num: '1'});
                    } else {
                        if (c.points == res.data[index - 1].points) {
                            newTab.push({data: c, num: '-'});
                        } else {
                            newTab.push({data: c, num: index + 1});
                        }
                    }
                });
                setClassJournee(newTab);
            });
        }

    }, [journeeSelected]);


    const deterMinerCouleurLigne = (index) => {
        if (index === 1) {
            return '#ffff4d';
        } else if (index === 2) {
            return '#ffffb3';
        } else if (index === 3) {
            return '#ffffb3';
        } else if (index === classJournee.length) {
            return '#ff6666';
        } else if (index === classJournee.length - 1) {
            return '#ffcccc';
        } else if (index === classJournee.length - 2) {
            return '#ffcccc'
        }
    };
    return (
        <Container sx={{width:'120%'}}>
            <FormControl fullWidth>
                <InputLabel id="select-label-journees">Journées</InputLabel>
                <Select
                    labelId="select-label-journees"
                    id="select-journees"
                    value={journeeSelected}
                    label="Journées"
                    size="small"
                    onChange={(e) => {
                        setJourneeSelected(e.target.value)
                    }}
                >
                    {allJournees}
                </Select>
            </FormControl>

            <TableContainer component={Paper} sx={{marginTop: 2, paddingBottom: 2}}>
                <Table>
                    <TableHead>
                        <TableRow sx={{backgroundColor: '#e6b3ff'}}>
                            <TableCell colSpan={2} align="left">
                                Journée {journeeSelected}
                            </TableCell>
                            <TableCell align="center">
                                Pts
                            </TableCell>
                            <TableCell align="center">
                                M. OK
                            </TableCell>
                            <TableCell align="center">
                                Pts perdu
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            classJournee.map((row, index) =>
                                <TableRow key={row?.data?.clePrimaireClassement?.userApp?.username?.toUpperCase()}
                                          sx={{backgroundColor: deterMinerCouleurLigne(index + 1)}}>
                                    <StyledTableCell align="left">{row.num}</StyledTableCell>
                                    <StyledTableCellUser
                                        align="left">{row?.data?.clePrimaireClassement?.userApp?.username?.toUpperCase()}</StyledTableCellUser>
                                    <StyledTableCell align="center">{row.data.points}</StyledTableCell>
                                    <StyledTableCell align="center">{row.data.matchOK}</StyledTableCell>
                                    <StyledTableCell
                                        align="center">{row.data.perduBombes > 0 ? `- ${row.data.perduBombes}` : 0}
                                    </StyledTableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}
