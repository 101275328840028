import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import axios from "axios";
import {AuthContext} from "./auth-context";
import {Navigate, useNavigate} from "react-router-dom";
import {User} from "../model/UserClass";
import ConnexionContainer from "./ConnexionContainer";


class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            login: '',
            mdp: ''
        };
        this.handleClickCreationCompte = this.handleClickCreationCompte.bind(this);
        this.handleClickConnexion = this.handleClickConnexion.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleClickConnexion(e) {
        e.preventDefault();
        //TODO test notif
      //  this.sendMessageAll();

        const data = new URLSearchParams();
        data.append('username', this.state.login);
        data.append('password', this.state.mdp);
        axios.post('/api/login', data).then((res) => {
            this.context.signin(res.data);
            this.props.navigate('/accueil');
        }).catch((error) => {
            alert('Erreur dans le login');
        });




    }


    sendMessageAll() {

        const data = JSON.stringify({"title": "test", "clickTarget": "prout",'message':'prout test'});
        return axios.post('/notify-all', data,{
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            console.log(res);
        }).catch((error) => {
            console.error(error);
        });
    }

    handleClickCreationCompte(e) {
        this.props.navigate('/creationCompte');
    }


    render() {
        return (
            <ConnexionContainer>
                <div>
                    <form onSubmit={this.handleClickConnexion}>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >

                            <TextField
                                id="login"
                                label="Login"
                                name="login"
                                variant="outlined"
                                onChange={this.handleInputChange}
                                required
                            />
                            <TextField
                                id="mdp"
                                name="mdp"
                                label="Mot de passe"
                                variant="outlined"
                                onChange={this.handleInputChange}
                                required
                                type="password"
                            />
                            <Button variant="outlined" size="medium" type="submit">
                                Se connecter
                            </Button>
                            <Button onClick={this.handleClickCreationCompte} size="small">Création d'un compte</Button>
                        </Stack>
                    </form>
                </div>
            </ConnexionContainer>
        );
    }
}

Login.contextType = AuthContext;

function WithNavigate() {
    let navigate = useNavigate();
    return (<Login navigate={navigate}></Login>);
}

export default WithNavigate
