import axios from "axios";

/**
 * Step two: The serviceworker is registered (started) in the browser. Now we
 * need to check if push messages and notifications are supported in the browser
 */
export function initialiseState(user, setOpen) {
    // Check if desktop notifications are supported
    if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
        console.warn('Notifications aren\'t supported.');
        return;
    }

    // Check if user has disabled notifications
    // If a user has manually disabled notifications in his/her browser for
    // your page previously, they will need to MANUALLY go in and turn the
    // permission back on. In this statement you could show some UI element
    // telling the user how to do so.
    if (Notification.permission === 'denied') {
        console.warn('The user has blocked notifications.');
        return;
    }

    // Check is push API is supported
    if (!('PushManager' in window)) {
        console.warn('Push messaging isn\'t supported.');
        return;
    }

    navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {

        // Get the push notification subscription object
        serviceWorkerRegistration.pushManager.getSubscription().then(function (subscription) {

            // If this is the user's first visit we need to set up
            // a subscription to push notifications
            if (!subscription) {
                subscribe(user);
                setOpen(true);
                return;
            }

            // Update the server state with the new subscription
            sendSubscriptionToServer(subscription,user);
        })
            .catch(function (err) {
                // Handle the error - show a notification in the GUI
                console.warn('Error during getSubscription()', err);
            });
    });
}

/**
 * Step three: Create a subscription. Contact the third party push server (for
 * example mozilla's push server) and generate a unique subscription for the
 * current browser.
 */
export function subscribe(user) {
    navigator.serviceWorker.ready.then((serviceWorkerRegistration) => {

        // Contact the third party push server. Which one is contacted by
        // pushManager is  configured internally in the browser, so we don't
        // need to worry about browser differences here.
        //
        // When .subscribe() is invoked, a notification will be shown in the
        // user's browser, asking the user to accept push notifications from
        // <yoursite.com>. This is why it is async and requires a catch.
        let appliServerKey = urlBase64ToUint8Array('BPJC9XN4RmKZysIS62Dedz-L5OXioX9IwWYvK0l4H5rksas33p-NZpZOEAwmJw9v3cMrxEpbDyHCG1MtQl3F9rI');
        serviceWorkerRegistration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: appliServerKey
        }).then(function (subscription) {

            // Update the server state with the new subscription
            return sendSubscriptionToServer(subscription,user);
        }, (error) => {
            console.error(error);
        })
            .catch(function (e) {
                if (Notification.permission === 'denied') {
                    console.warn('Permission for Notifications was denied');
                } else {
                    console.error('Unable to subscribe to push.', e);
                }
            });
    });
}


/**
 * Step four: Send the generated subscription object to our server.
 */
function sendSubscriptionToServer(subscription, username) {
    const userApp =  {
            username: username.username,
            password: '',
            enabled: true,
            banane: '',
            cerise: '',
            mail: '',
            lettre: ''
        };
    // Get public key and user auth from the subscription object
    var key = subscription.getKey ? subscription.getKey('p256dh') : '';
    var auth = subscription.getKey ? subscription.getKey('auth') : '';

    const data = JSON.stringify({
        'notificationEndPoint':  subscription.endpoint,
        'publicKey': key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : '',
        'auth':auth ? btoa(String.fromCharCode.apply(null, new Uint8Array(auth))) : '',
        'username': userApp
    });

    // This example uses the new fetch API. This is not supported in all
    // browsers yet.
    return axios.post('/subscribe', data, {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then((res) => {
        console.log(res);
    }).catch((error) => {
        console.error(error);
    });


}


// This function is needed because Chrome doesn't accept a base64 encoded string
// as value for applicationServerKey in pushManager.subscribe yet
// https://bugs.chromium.org/p/chromium/issues/detail?id=802280
function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
