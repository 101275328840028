import React from 'react';
import './App.css';
import axios from "axios";
import RouterComponent from "./components/RouterComponent";

export default class App extends React.Component {

    componentDidMount() {

    }


    render() {

        //  const notification = new Notification('To do list', { body: "coucou" });
        axios.defaults.withCredentials = true;
        //axios.defaults.baseURL = "https://8090-mfl11-mflbackend-dhkp3gejirs.ws-eu114.gitpod.io/";
        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 403) {
                    window.location.href = '/';
                } else {
                    return Promise.reject(error);
                }
            }
        );

        return (

            <div className="App">
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/icon?family=Material+Icons"
                />

                <RouterComponent/>
            </div>
        );
    }


}


