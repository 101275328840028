export class Parier {
    constructor(abb_Dom, abb_Ext, bombe, cerise, idRencontres, nomclub_dom, nomclub_ext, onfire, username, valeurParis, grappa) {
        this.abb_Dom = abb_Dom;
        this.abb_Ext = abb_Ext;
        this.bombe = bombe;
        this.cerise = cerise;
        this.idRencontres = idRencontres;
        this.nomclub_dom = nomclub_dom;
        this.nomclub_ext = nomclub_ext;
        this.onfire = onfire;
        this.username = username;
        this.valeurParis = valeurParis;
        this.grappa = grappa;
    }
}
