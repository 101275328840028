import {createTheme} from "@mui/material/styles";

export const Theme = createTheme({
    status: {
        danger: '#e53e3e',
    },
    palette: {
        primary: {
            main: '#3F2B56',
            darker: '#053e85',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
        dom: {
            main: '#3399ff',
            contrastText: '#fff',
        }
    },
});
