import React, {useContext, useEffect} from "react";
import Container from "@mui/material/Container";
import axios from "axios";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LigneClassementGeneral from "./LigneClassementGeneral";
import {AuthContext} from "./auth-context";
import {styled} from "@mui/material/styles";

export default function ClassementGeneral() {
    let context = useContext(AuthContext);
    const [classementGeneral, setClassementGeneral] = React.useState([]);
    const [ptsJoueur, setPtsJoueur] = React.useState(0);

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            fontSize: 14,
            padding:10
        }
    }));

    const deterMinerCouleurLigne = (index) => {
        if (index === 1) {
            return '#ffff4d';
        } else if (index === 2) {
            return '#ffffb3';
        } else if (index === 3) {
            return '#ffffb3';
        } else if (index === classementGeneral.length) {
            return '#ff6666';
        } else if (index === classementGeneral.length - 1) {
            return '#ffcccc';
        } else if (index === classementGeneral.length - 2) {
            return '#ffcccc'
        }
    };

    useEffect(() => {
        axios.all([
            axios.get('/api/classement/general'),
            axios.get('/api/user/all')
        ]).then((res) => {
            const newTab = [];
            res[0].data.forEach((c, index) => {
                let titre = res[1].data.find(x => x.pseudo === c.username)?.titre
                if (index === 0) {
                    newTab.push({data: c, num: '1', titre: titre});
                } else {
                    if (c.total === res[0].data[index - 1].total && c.pourcentages === res[0].data[index - 1].pourcentages) {
                        newTab.push({data: c, num: '-', titre: titre});
                    } else {
                        newTab.push({data: c, num: index + 1, titre: titre});
                    }
                }
                
            });

            setPtsJoueur(res[0].data.find( u => u.username === context.user.username)?.total);
            setClassementGeneral(newTab);
        });
        /*
        axios.get('/api/classement/general').then((res) => {
            const newTab = [];
            res.data.forEach((c, index) => {
                if (index === 0) {
                    newTab.push({data: c, num: '1'});
                } else {
                    if (c.total === res.data[index - 1].total && c.pourcentages === res.data[index - 1].pourcentages) {
                        newTab.push({data: c, num: '-'});
                    } else {
                        newTab.push({data: c, num: index + 1});
                    }
                }
            });
            setPtsJoueur(res.data.find( u => u.username === context.user.username)?.total);
            setClassementGeneral(newTab);
        });
        */
    }, []);

    const calculDiffPts = (pts) => {
        let diff = ptsJoueur - pts;

        if (diff > 0) {
            return `+${diff} pts 👏`
        } else {
            return `${diff} pts 😅`
        }
    };

    return (
        <Container sx={{width:'120%'}}>
            <TableContainer component={Paper} sx={{paddingBottom: 2}}>
                <Table>
                    <TableHead>
                        <TableRow sx={{backgroundColor: '#e6b3ff'}}>
                            <StyledTableCell colSpan={2}>Général</StyledTableCell>
                            <StyledTableCell align="center">Pts</StyledTableCell>
                            <StyledTableCell align="center">%</StyledTableCell>
                            <StyledTableCell align="center">M.Ok</StyledTableCell>
                            <StyledTableCell align="center"><TrendingUpIcon></TrendingUpIcon></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            classementGeneral.map((row, indexRow) =>
                                <LigneClassementGeneral key={row.data.username} row={row} couleurBack={deterMinerCouleurLigne(indexRow + 1)} diffPts={calculDiffPts(row.data.total)}/>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}
