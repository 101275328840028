import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import axios from "axios";
import {UserApp} from "../model/UserClass";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import ConnexionContainer from "./ConnexionContainer";
import {useNavigate} from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";

const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

class CreationCompte extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mail: '',
            login: '',
            mdp: '',
            mdp2: '',
            open: false,
            msgErreur: '',
            lettresTab: <MenuItem></MenuItem>,
            lettre: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        axios.get('/api/user/lettres').then((res) => {
            res.data = res.data.map( m => m.toLowerCase());
            const myArray = alphabet.filter((el) => !res.data.includes(el.toLowerCase()));

            this.setState({
                lettresTab: myArray.map((j) =>
                    <MenuItem key={j} value={j}>{j}</MenuItem>
                ),
                lettre: myArray[0]
            });
        }).catch((error) => {
            console.error(error);
            alert('Erreur appel lettres. Il faut prévenir le développeur.')
        })
    }

    handleSubmit(e) {
        e.preventDefault();
        const listErreur = [];
        if (!this.checkMDP()) {
            listErreur.push('MDP non identique');
        }

        if (!this.checkMail()) {
            listErreur.push('Mail invalide');
        }
        if (!listErreur.length) {
            const user = new UserApp();
            user.username = this.state.login;
            user.mail = this.state.mail;
            user.password = this.state.mdp;
            user.lettre = this.state.lettre;
            axios.post('/api/user/creationCompte', user).then((res) => {
                if (res.status === 200) {
                    this.props.navigate('/');
                }
            }).catch((error) => {
                console.error(error);
                if (error.response.status === 409) {
                    this.setState({
                        open: true,
                        msgErreur: 'Ce pseudo existe déjà.'
                    });
                } else {
                    this.setState({
                        open: true,
                        msgErreur: 'Erreur inconnue.'
                    });
                }
            });
            //
        } else {
            listErreur.map(c => (<p>`${c}`</p>));
            alert(listErreur.toString());
        }
    }

    handleInputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        if (name === 'mail') {
            this.checkMail();
        }
        if (name === 'mdp2') {
            this.checkMDP();
        }
        this.setState({
            [name]: value
        });
    }

    checkMDP() {
        if (this.state.mdp !== this.state.mdp2) {
            return false;
        } else {
            return true;
        }
    }

    checkMail() {
        if (this.state.mail.indexOf('@') >= 0) {
            return true;
        } else {
            return false;
        }
    }

    handleClose(event, reason) {
        this.setState({
            open: false
        })
    }

    render() {

        const open = this.state.open;
        const msgErreur = this.state.msgErreur;

        return (
            <ConnexionContainer>
                <div>
                    <Snackbar open={open} autoHideDuration={6000} onClose={this.handleClose} key={'top' + 'center'}
                              anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
                        <Alert onClose={this.handleClose} severity="error" sx={{width: '100%'}}>
                            {msgErreur}
                        </Alert>
                    </Snackbar>
                    <form onSubmit={this.handleSubmit}>
                        <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <TextField
                                id="mail"
                                label="Mail"
                                variant="outlined"
                                required
                                type="email"
                                name='mail'
                                onChange={this.handleInputChange}
                            />
                            <TextField
                                id="login"
                                label="Login"
                                variant="outlined"
                                name='login'
                                onChange={this.handleInputChange}
                                required
                            />
                            <FormControl fullWidth>
                                <InputLabel id="select-label-lettres">Lettre</InputLabel>
                                <Select
                                    labelId="select-label-lettres"
                                    id="select-journees"
                                    value={this.state.lettre}
                                    label="Journées"
                                    size="medium"
                                    onChange={(e) => {
                                        this.setState({
                                            lettre : e.target.value
                                        });
                                    }}
                                >
                                    {this.state.lettresTab}
                                </Select>
                                <FormHelperText>Choisis ta lettre pour la Grille</FormHelperText>
                            </FormControl>
                            <TextField
                                id="mdp"
                                label="Mot de passe"
                                variant="outlined"
                                name='mdp'
                                onChange={this.handleInputChange}
                                required
                                type="password"
                            />
                            <TextField
                                id="mdp2"
                                name='mdp2'
                                label="Vérifier mot de passe"
                                variant="outlined"
                                onChange={this.handleInputChange}
                                required
                                type="password"
                            />
                            <Button variant="outlined" size="medium" type="submit">
                                Créer le compte
                            </Button>
                        </Stack>
                    </form>
                </div>
            </ConnexionContainer>
        );
    }
}

function WithNavigateCrea() {
    let navigate = useNavigate();
    return (<CreationCompte navigate={navigate}></CreationCompte>);
}

export default WithNavigateCrea
