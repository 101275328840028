import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import fire from "../assets/fire.png";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grenade from "../assets/grenade.png";
import axios from "axios";
import {styled} from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";

export default function LigneGrille(props) {

    const {row} = props;

    const [open, setOpen] = React.useState(false);

    const StyledTableCell = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            width: '50px',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            width: '33%',
            wordBreak: 'break-all',
            verticalAlign: 'top'
        },
    }));

    const StyledTableCellPseudo = styled(TableCell)(({theme}) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            width: '50px',
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 9,
            width: '33%',
            wordBreak: 'break-all',
            verticalAlign: 'top'
        },
    }));

    const definirBombesAffichage = (nbBombes, idMatch) => {
        let res = [];

        for (let i = 0; i < +nbBombes; i++) {
            res.push(<div key={`bombes_${idMatch}_${i}`} style={{
                backgroundImage: `url(${Grenade})`,
                width: '38px',
                height: '41px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                float: 'right',
                marginTop: '-15px'
            }}></div>);
        }
        return res;
    };

    const datePipe = (date) => {
        let dateD = new Date(date);
        return `${dateD.toLocaleDateString()} à ${dateD.toLocaleTimeString()}`;
    };

    const onClickCollapse = () => {

    };


    return (
        <TableContainer key={row.id_rencontres} sx={{marginTop: '10px', marginBottom: '10px'}}
                        onClick={() => setOpen(!open)}>
            <Table sx={{display: 'solid'}}>
                <TableHead>
                    <TableRow sx={{
                        backgroundColor: '#e6b3ff',
                        backgroundImage: row.nbonfire ? `url(${fire})` : '',
                        backgroundPositionY: '-31px'
                    }}>
                        <TableCell align="center" colSpan="3">
                            {datePipe(row.date)}
                            {definirBombesAffichage(row.nbbombes, row.id_rencontres)}

                        </TableCell>
                    </TableRow>
                    <TableRow sx={{backgroundColor: '#e6e6ff'}}>
                        <TableCell align="center">
                            {row.equipes.abbreviation} {row.but_dom}
                        </TableCell>
                        <TableCell align="center">
                            -
                        </TableCell>
                        <TableCell align="center">
                            {row.but_ext} {row.equipesExt.abbreviation}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <StyledTableCell align="center">
                            <Container sx={{height: '30px', marginTop: '0px'}}>
                                {row.pts_dom ? row.pts_dom : '-'}
                            </Container>
                            <hr/>
                            <Typography fontSize={9}>
                                {props.grilleLettres[row.id_rencontres].dom}
                            </Typography>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <hr/>
                                <Typography fontSize={9}>
                                    {props.grillePseudos[row.id_rencontres].dom}
                                </Typography>
                            </Collapse>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Container sx={{height: '30px', marginTop: '0px'}}>
                                {row.pts_nul ? row.pts_nul : '-'}
                            </Container>
                            <hr/>
                            <Typography fontSize={9}>
                                {props.grilleLettres[row.id_rencontres]?.nul}
                            </Typography>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <hr/>
                                <Typography fontSize={9}>
                                    {props.grillePseudos[row.id_rencontres]?.nul}
                                </Typography>
                            </Collapse>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                            <Container sx={{height: '30px'}}>
                                {row.pts_ext ? row.pts_ext : '-'}
                            </Container>
                            <hr/>
                            <Typography fontSize={9}>
                                {props.grilleLettres[row.id_rencontres]?.ext}
                            </Typography>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <hr/>
                                <Typography fontSize={9}>
                                    {props.grillePseudos[row.id_rencontres]?.ext}
                                </Typography>
                            </Collapse>
                        </StyledTableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

    );

}
